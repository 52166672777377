import { useCallback } from 'react';

import { Tail } from '@totopkg/shared-util-common';

import {
  betAction,
  cancelBetAction,
  fetchGameInfoAction,
  fetchGameSchemaAction,
  fetchHistoryResultsAction,
  reBetAction,
  transferSelectedToAddedBetItemsAction
} from '../action';
import {
  clearAddedBetItemsAction,
  clearSelectedBetItemsAction,
  removeAddedBetItemAction,
  toggleSelectedBetItemAction,
  updateAddedBetItemAmountAction,
  updateAllSelectedBetItemsAmountAction,
  updateGeneralSelectedBetAmountAction
} from '../mutator-action';
import {
  addedBetItemsSelector,
  addedBetItemsStatsSelector,
  currentGameInfoSelector,
  gameSchemaByBetLocationSelector,
  generalSelectedBetAmountSelector,
  historyResultsSelector,
  isBettingSelector,
  isCancellingBetSelector,
  isFetchingGameInfoSelector,
  isFetchingHistoryResultsSelector,
  latestGameInfoSelector,
  ratioWinByBetLocationSelector,
  selectedBetItemsSelector,
  selectedBetItemsStatsSelector
} from '../selector';

export const useGameSicboHook = (params: { schedulerId: string | undefined }) => {
  const { schedulerId } = params;

  const isBetting = isBettingSelector();
  const isCancellingBet = isCancellingBetSelector();
  const isFetchingGameInfo = isFetchingGameInfoSelector();
  const isFetchingHistoryResults = isFetchingHistoryResultsSelector();

  const isLoading = isBetting || isCancellingBet || isFetchingGameInfo || isFetchingHistoryResults;

  const fetchGameInfo = useCallback(
    (...args: Tail<Parameters<typeof fetchGameInfoAction>>) => {
      fetchGameInfoAction(schedulerId, ...args);
    },
    [schedulerId]
  );

  const fetchGameSchema = useCallback(
    (...args: Tail<Parameters<typeof fetchGameSchemaAction>>) => {
      fetchGameSchemaAction(schedulerId, ...args);
    },
    [schedulerId]
  );

  const fetchHistoryResults = useCallback(
    (...args: Tail<Parameters<typeof fetchHistoryResultsAction>>) => {
      fetchHistoryResultsAction(schedulerId, ...args);
    },
    [schedulerId]
  );

  const toggleSelectedBetItem = useCallback(
    (...args: Tail<Parameters<typeof toggleSelectedBetItemAction>>) => {
      toggleSelectedBetItemAction(schedulerId, ...args);
    },
    [schedulerId]
  );

  const clearSelectedBetItems = useCallback(
    (...args: Tail<Parameters<typeof clearSelectedBetItemsAction>>) => {
      clearSelectedBetItemsAction(schedulerId, ...args);
    },
    [schedulerId]
  );

  const updateGeneralSelectedBetAmount = useCallback(
    (...args: Tail<Parameters<typeof updateGeneralSelectedBetAmountAction>>) => {
      updateGeneralSelectedBetAmountAction(schedulerId, ...args);
    },
    [schedulerId]
  );

  const updateAllSelectedBetItemsAmount = useCallback(
    (...args: Tail<Parameters<typeof updateAllSelectedBetItemsAmountAction>>) => {
      updateAllSelectedBetItemsAmountAction(schedulerId, ...args);
    },
    [schedulerId]
  );

  const transferSelectedToAddedBetItems = useCallback(() => {
    transferSelectedToAddedBetItemsAction(schedulerId);
  }, [schedulerId]);

  const updateAddedBetItemAmount = useCallback(
    (...args: Tail<Parameters<typeof updateAddedBetItemAmountAction>>) => {
      updateAddedBetItemAmountAction(schedulerId, ...args);
    },
    [schedulerId]
  );

  const removeAddedBetItem = useCallback(
    (...args: Tail<Parameters<typeof removeAddedBetItemAction>>) => {
      removeAddedBetItemAction(schedulerId, ...args);
    },
    [schedulerId]
  );

  const clearAddedBetItems = useCallback(() => {
    clearAddedBetItemsAction(schedulerId);
  }, [schedulerId]);

  const bet = useCallback(
    (...args: Tail<Parameters<typeof betAction>>) => {
      betAction(schedulerId, ...args);
    },
    [schedulerId]
  );

  const reBet = useCallback(
    (...args: Tail<Parameters<typeof reBetAction>>) => {
      reBetAction(schedulerId, ...args);
    },
    [schedulerId]
  );

  const cancelBet = useCallback((...args: Parameters<typeof cancelBetAction>) => {
    cancelBetAction(...args);
  }, []);

  const gameSchemaByBetLocation = useCallback(
    (...args: Tail<Parameters<typeof gameSchemaByBetLocationSelector>>) => {
      return gameSchemaByBetLocationSelector(schedulerId, ...args);
    },
    [schedulerId]
  );

  const ratioWinByBetLocation = useCallback(
    (...args: Tail<Parameters<typeof ratioWinByBetLocationSelector>>) => {
      return ratioWinByBetLocationSelector(schedulerId, ...args);
    },
    [schedulerId]
  );

  return {
    gameSchemaByBetLocation,
    ratioWinByBetLocation,

    currentGameInfo: currentGameInfoSelector(schedulerId),
    latestGameInfo: latestGameInfoSelector(schedulerId),
    addedBetItems: addedBetItemsSelector(schedulerId),
    addedBetItemsStats: addedBetItemsStatsSelector(schedulerId),
    selectedBetItems: selectedBetItemsSelector(schedulerId),
    selectedBetItemsStats: selectedBetItemsStatsSelector(schedulerId),
    generalSelectedBetAmount: generalSelectedBetAmountSelector(schedulerId),
    historyResults: historyResultsSelector(schedulerId),

    isBetting,
    isFetchingGameInfo,
    isFetchingHistoryResults,
    isLoading,

    fetchGameInfo,
    fetchGameSchema,
    fetchHistoryResults,

    toggleSelectedBetItem,
    clearSelectedBetItems,
    updateGeneralSelectedBetAmount,
    updateAllSelectedBetItemsAmount,
    transferSelectedToAddedBetItems,
    updateAddedBetItemAmount,
    removeAddedBetItem,
    clearAddedBetItems,

    bet,
    reBet,
    cancelBet
  };
};
