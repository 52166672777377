import { API } from '@totopkg/shared-util-api';

import { ISicboBetItem } from '../store';

export interface ISicboBetRequestData {
  ccy?: string;
  betItems: Omit<ISicboBetItem, 'ratioWin'>[];
}

export interface ISicboBetResponse {
  id?: string;
  accountId?: string;
  username?: string;
  ccy?: string;
  timeBet?: string;
  totalBetAmt?: number;
  totalBetUnit?: number;
  totalWonAmt?: number;
  totalWonUnit?: number;
  totalPayoutAmt?: number;
  betItems?: ISicboBetItem[];
  genealogy?: string;
  accountType?: string;
  status?: string;
  issueId?: string;
  result?: string;
  scheduler?: string;
  riskLevel?: number;
  parentUsername?: string;
}

export const postSicboBetAPI = async (schedulerId: string, data: ISicboBetRequestData) => {
  const response = await API.post<ISicboBetResponse>(`/minigame/games/${schedulerId}/bet`, {
    ccy: data?.ccy || 'VND',
    ...data
  });

  return response.data;
};
