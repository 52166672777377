import { addedBetItemsSelector } from './added-bet-items.selector';
import { ratioWinByBetLocationSelector } from './ratio-win-by-bet-location.selector';

export const addedBetItemsStatsSelector = (schedulerId: string | undefined) => {
  const addedBetItems = addedBetItemsSelector(schedulerId) ?? [];

  const stats = addedBetItems?.reduce(
    (acc, betItem) => {
      const { betLocation, betAmt } = betItem;

      if (!betLocation || !betAmt) return acc;

      const ratioWin = ratioWinByBetLocationSelector(schedulerId, betLocation);

      return {
        totalBetAmount: acc.totalBetAmount + betAmt,
        totalPossibleWinAmount: acc.totalPossibleWinAmount + betAmt * ratioWin
      };
    },
    {
      totalBetAmount: 0,
      totalPossibleWinAmount: 0
    }
  );

  return stats;
};
