import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { ISicboBetResponse } from '../api';
import { ISicboBetItem } from '../store';

export const reBetAction = action(
  'reBetAction',
  (schedulerId: string | undefined, betItems: ISicboBetItem[], callback?: TActionCallback<ISicboBetResponse>) => ({
    schedulerId,
    betItems,
    callback
  })
);
