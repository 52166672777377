import { mutatorAction } from 'satcheljs';

import { getStore } from '../store';
import { ISicboGameInfo } from '../type';

export const updateLatestGameInfoAction = mutatorAction(
  'updateLatestGameInfoAction',
  (schedulerId: string | undefined, gameInfo: ISicboGameInfo) => {
    if (!schedulerId) return;

    getStore().latestGameInfoMap.set(schedulerId, gameInfo);
  }
);
