import { observable } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { generalSelectedBetAmountSelector, selectedBetItemsSelector } from '../selector';
import { getStore, ISicboBetItem, TSicboBetLocationType } from '../store';

export const toggleSelectedBetItemAction = mutatorAction(
  'toggleSelectedBetItemAction',
  (schedulerId: string | undefined, selectedBetLocation: TSicboBetLocationType) => {
    if (!schedulerId) return;

    const selectedBetItemsState = selectedBetItemsSelector(schedulerId);
    const generalSelectedBetAmount = generalSelectedBetAmountSelector(schedulerId);

    // bet item already exist in store will be remove, otherwise, just add new bet items
    let mergedSelectedBetItems: ISicboBetItem[] = [];

    const targetBetItemsState = selectedBetItemsState?.find(betItemState => betItemState.betLocation === selectedBetLocation);

    if (targetBetItemsState) {
      mergedSelectedBetItems = selectedBetItemsState?.filter(betItemState => betItemState.betLocation !== selectedBetLocation) ?? [];
    } else {
      mergedSelectedBetItems = [...(selectedBetItemsState ?? []), { betLocation: selectedBetLocation, betAmt: generalSelectedBetAmount }];
    }

    getStore().selectedBetItemsMap.set(schedulerId, observable.array(mergedSelectedBetItems));
  }
);
