import { observable } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getStore } from '../store';
import { IHistoryResultRecord } from '../type';

export const updateHistoryResultsAction = mutatorAction(
  'updateHistoryResultsAction',
  (schedulerId: string | undefined, results: IHistoryResultRecord[], options?: { isUnshift?: boolean }) => {
    const { isUnshift } = options ?? {};

    if (!schedulerId) return;

    if (isUnshift) {
      getStore()
        .historyResultsMap.get(schedulerId)
        ?.unshift(...results);
    } else {
      getStore().historyResultsMap.set(schedulerId, observable.array(results));
    }
  }
);
