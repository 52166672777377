import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { ISicboBetResponse } from '../api';

export const betAction = action(
  'betAction',
  (schedulerId: string | undefined, params?: { isQuickBet?: boolean; callback?: TActionCallback<ISicboBetResponse> }) => ({
    schedulerId,
    ...params
  })
);
