import { mutatorAction } from 'satcheljs';

import { getStore } from '../store';

export const updateGeneralSelectedBetAmountAction = mutatorAction(
  'updateGeneralSelectedBetAmountAction',
  (schedulerId: string | undefined, amount: number | undefined) => {
    if (!schedulerId) return;

    getStore().generalSelectedBetAmtMap.set(schedulerId, amount ?? 0);
  }
);
