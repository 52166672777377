import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

import { IGameSicboStore } from './game-sicbo.type';

export const initStore: IGameSicboStore = {
  currentGameInfoMap: new ObservableMap(),
  latestGameInfoMap: new ObservableMap(),
  gameSchemasMap: new ObservableMap(),

  generalSelectedBetAmtMap: new ObservableMap(),
  selectedBetItemsMap: new ObservableMap(),
  addedBetItemsMap: new ObservableMap(),

  historyResultsMap: new ObservableMap()
};

export const getStore = createStore('gameSicboStore', initStore);
