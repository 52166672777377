import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

export const fetchGameInfoAction = action(
  'fetchGameInfoAction',
  (schedulerId: string | undefined, options?: { isOnMount?: boolean; callback?: TActionCallback }) => ({
    schedulerId,
    ...options
  })
);
