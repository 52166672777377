import { IObservableArray, ObservableMap } from 'mobx';

import { IGameSchema, IHistoryResultRecord, ISicboGameInfo } from '../type';

export type TSchedulerId = string;

export enum EDiceFace {
  ONE = 1,
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX
}

export type TSicboBetLocationType =
  | 'SICBO_BIG'
  | 'SICBO_SMALL'
  | 'SICBO_EVEN'
  | 'SICBO_ODD'
  | 'SICBO_SINGLE_1'
  | 'SICBO_SINGLE_2'
  | 'SICBO_SINGLE_3'
  | 'SICBO_SINGLE_4'
  | 'SICBO_SINGLE_5'
  | 'SICBO_SINGLE_6'
  | 'SICBO_DOUBLE_1'
  | 'SICBO_DOUBLE_2'
  | 'SICBO_DOUBLE_3'
  | 'SICBO_DOUBLE_4'
  | 'SICBO_DOUBLE_5'
  | 'SICBO_DOUBLE_6'
  | 'SICBO_TRIPLE_1'
  | 'SICBO_TRIPLE_2'
  | 'SICBO_TRIPLE_3'
  | 'SICBO_TRIPLE_4'
  | 'SICBO_TRIPLE_5'
  | 'SICBO_TRIPLE_6'
  | 'SICBO_TRIPLE_ANY'
  | 'SICBO_TWO_DICE_1And2'
  | 'SICBO_TWO_DICE_1And3'
  | 'SICBO_TWO_DICE_1And4'
  | 'SICBO_TWO_DICE_1And5'
  | 'SICBO_TWO_DICE_1And6'
  | 'SICBO_TWO_DICE_2And3'
  | 'SICBO_TWO_DICE_2And4'
  | 'SICBO_TWO_DICE_2And5'
  | 'SICBO_TWO_DICE_2And6'
  | 'SICBO_TWO_DICE_3And4'
  | 'SICBO_TWO_DICE_3And5'
  | 'SICBO_TWO_DICE_3And6'
  | 'SICBO_TWO_DICE_4And5'
  | 'SICBO_TWO_DICE_4And6'
  | 'SICBO_TWO_DICE_5And6'
  | 'SICBO_SUM_4'
  | 'SICBO_SUM_5'
  | 'SICBO_SUM_6'
  | 'SICBO_SUM_7'
  | 'SICBO_SUM_8'
  | 'SICBO_SUM_9'
  | 'SICBO_SUM_10'
  | 'SICBO_SUM_11'
  | 'SICBO_SUM_12'
  | 'SICBO_SUM_13'
  | 'SICBO_SUM_14'
  | 'SICBO_SUM_15'
  | 'SICBO_SUM_16'
  | 'SICBO_SUM_17';

export type TSicboResultType = `${EDiceFace}${EDiceFace}${EDiceFace}`;

export interface ISicboBetItem {
  betLocation: TSicboBetLocationType | undefined;
  betAmt: number | undefined;
}

export interface IGameSicboStore {
  currentGameInfoMap: ObservableMap<TSchedulerId, ISicboGameInfo>;
  latestGameInfoMap: ObservableMap<TSchedulerId, ISicboGameInfo>;
  gameSchemasMap: ObservableMap<TSchedulerId, IObservableArray<IGameSchema>>;

  generalSelectedBetAmtMap: ObservableMap<TSchedulerId, number>;
  selectedBetItemsMap: ObservableMap<TSchedulerId, IObservableArray<ISicboBetItem>>;
  addedBetItemsMap: ObservableMap<TSchedulerId, IObservableArray<ISicboBetItem>>;

  historyResultsMap: ObservableMap<TSchedulerId, IObservableArray<IHistoryResultRecord>>;
}
