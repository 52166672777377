import { ratioWinByBetLocationSelector } from './ratio-win-by-bet-location.selector';
import { selectedBetItemsSelector } from './selected-bet-items.selector';

export const selectedBetItemsStatsSelector = (schedulerId: string | undefined) => {
  const selectedBetItems = selectedBetItemsSelector(schedulerId) ?? [];

  const stats = selectedBetItems?.reduce(
    (acc, betItem) => {
      const { betLocation, betAmt } = betItem;

      if (!betLocation || !betAmt) return acc;

      const ratioWin = ratioWinByBetLocationSelector(schedulerId, betLocation);

      return {
        totalBetAmount: acc.totalBetAmount + betAmt,
        totalPossibleWinAmount: acc.totalPossibleWinAmount + betAmt * ratioWin
      };
    },
    {
      totalBetAmount: 0,
      totalPossibleWinAmount: 0
    }
  );

  return stats;
};
