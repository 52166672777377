import { orchestrator } from 'satcheljs';

import { LoadingStore } from '@totopkg/shared-util-common';

import { fetchHistoryResultsAction } from '../action';
import { getHistoryResultsAPI } from '../api';
import { IS_FETCHING_HISTORY_RESULTS } from '../const';
import { updateHistoryResultsAction } from '../mutator-action';
import { historyResultsSelector, isFetchingHistoryResultsSelector } from '../selector';

orchestrator(fetchHistoryResultsAction, async actionMessage => {
  const { schedulerId, callback, isForce } = actionMessage;

  const isFetchingHistoryResults = isFetchingHistoryResultsSelector();

  if (!schedulerId || isFetchingHistoryResults) return callback?.finally?.();

  const historyResults = historyResultsSelector(schedulerId);

  if (historyResults && !isForce) return callback?.finally?.();

  LoadingStore.updateLocalLoadingAction(IS_FETCHING_HISTORY_RESULTS, true);

  try {
    const response = await getHistoryResultsAPI(schedulerId);

    updateHistoryResultsAction(schedulerId, response);
    callback?.success?.();
  } catch (error) {
    callback?.error?.(String(error));
  } finally {
    LoadingStore.updateLocalLoadingAction(IS_FETCHING_HISTORY_RESULTS, false);
    callback?.finally?.();
  }
});
