import { mutatorAction } from 'satcheljs';

import { addedBetItemsSelector } from '../selector';
import { ISicboBetItem } from '../store';

export const updateAddedBetItemAmountAction = mutatorAction(
  'updateAddedBetItemAmountAction',
  (schedulerId: string | undefined, addedBetItem: ISicboBetItem) => {
    const addedBetItems = addedBetItemsSelector(schedulerId);

    if (!addedBetItems) return;

    const indexOfAddedBetItem = addedBetItems.findIndex(addedBetItemState => addedBetItemState.betLocation === addedBetItem.betLocation);

    if (indexOfAddedBetItem === -1) return;

    addedBetItems[indexOfAddedBetItem].betAmt = addedBetItem.betAmt;
  }
);
