import { orchestrator } from 'satcheljs';

import { resetBettingRoundAction } from '../action';
import { clearAddedBetItemsAction, clearSelectedBetItemsAction } from '../mutator-action';

orchestrator(resetBettingRoundAction, actionMessage => {
  const { schedulerId } = actionMessage;

  clearSelectedBetItemsAction(schedulerId);
  clearAddedBetItemsAction(schedulerId)
});
