import { orchestrator } from 'satcheljs';

import { LoadingStore } from '@totopkg/shared-util-common';

import { betAction, resetBettingRoundAction } from '../action';
import { postSicboBetAPI } from '../api';
import { IS_BETTING } from '../const';
import { addedBetItemsSelector, isBettingSelector, selectedBetItemsSelector } from '../selector';

orchestrator(betAction, async actionMessage => {
  const { schedulerId, isQuickBet, callback } = actionMessage;

  const isBetting = isBettingSelector();

  if (!schedulerId || isBetting) return callback?.finally?.();

  LoadingStore.updateLocalLoadingAction(IS_BETTING, true);

  try {
    const selectedBetItems = selectedBetItemsSelector(schedulerId);
    const addedBetItems = addedBetItemsSelector(schedulerId);

    const betItems = isQuickBet ? selectedBetItems : addedBetItems;

    if (!betItems || betItems.length === 0) {
      throw 'noBetItems';
    }

    const response = await postSicboBetAPI(schedulerId, {
      betItems: betItems.map(betItem => ({
        betLocation: betItem.betLocation,
        betAmt: betItem.betAmt
      }))
    });

    resetBettingRoundAction(schedulerId);

    callback?.success?.(response);
  } catch (error) {
    callback?.error?.(String(error ?? error));
  } finally {
    callback?.finally?.();
    LoadingStore.updateLocalLoadingAction(IS_BETTING, false);
  }
});
