import { observable } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { selectedBetItemsSelector } from '../selector';
import { getStore } from '../store';

export const updateAllSelectedBetItemsAmountAction = mutatorAction(
  'updateAllSelectedBetItemsAmountAction',
  (schedulerId: string | undefined, amount: number) => {
    if (!schedulerId) return;

    const selectedBetItems = selectedBetItemsSelector(schedulerId) ?? [];

    if (!selectedBetItems.length) return;

    getStore().selectedBetItemsMap.set(
      schedulerId,
      observable.array(selectedBetItems.map(betItem => ({ betLocation: betItem.betLocation, betAmt: amount })))
    );
  }
);
