import { orchestrator } from 'satcheljs';

import { fetchGameSchemaAction } from '../action';
import { getGameSchemaApi } from '../api';
import { updateGameSchemasAction } from '../mutator-action';
import { gameSchemasSelector } from '../selector';

orchestrator(fetchGameSchemaAction, async actionMessage => {
  const { schedulerId, callback } = actionMessage;

  const gameSchemas = gameSchemasSelector(schedulerId);

  if (!schedulerId || gameSchemas) return callback?.finally?.();

  try {
    const response = await getGameSchemaApi({ scheduler: schedulerId });

    updateGameSchemasAction(schedulerId, response);
    callback?.success?.();
  } catch (error) {
    callback?.error?.(String(error));
  } finally {
    callback?.finally?.();
  }
});
