import { TSicboResultType } from '../store';

export enum EIssueStatus {
  NEW = 'NEW',
  OPENING = 'OPENING',
  CLOSED = 'CLOSED',
  ENDED = 'ENDED',
  CANCELED = 'CANCELED',
  CANCELING = 'CANCELING'
}

export interface ISicboGameInfo {
  closeAt?: string;
  id?: string;
  openAt?: string;
  schedulerId?: string;
  specialNumber?: TSicboResultType;
  status?: EIssueStatus;
}
