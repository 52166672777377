import { mutatorAction } from 'satcheljs';

import { getStore } from '../store';
import { ISicboGameInfo } from '../type';

export const updateCurrentGameInfoAction = mutatorAction(
  'updateCurrentGameInfoAction',
  (schedulerId: string | undefined, gameInfo: ISicboGameInfo) => {
    if (!schedulerId) return;

    getStore().currentGameInfoMap.set(schedulerId, gameInfo);
  }
);
