import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { ISicboBetResponse } from '../api';

export const cancelBetAction = action('cancelBetAction', (ticketId: string | undefined, callback?: TActionCallback<ISicboBetResponse>) => ({
  ticketId,
  callback
}));
