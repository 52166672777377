import { orchestrator } from 'satcheljs';

import { LoadingStore } from '@totopkg/shared-util-common';

import { reBetAction } from '../action';
import { postSicboBetAPI } from '../api';
import { IS_BETTING } from '../const';
import { isBettingSelector } from '../selector';

orchestrator(reBetAction, async actionMessage => {
  const { schedulerId, callback, betItems } = actionMessage;

  const isBetting = isBettingSelector();

  if (!schedulerId || isBetting) return callback?.finally?.();

  LoadingStore.updateLocalLoadingAction(IS_BETTING, true);

  try {
    const response = await postSicboBetAPI(schedulerId, {
      betItems
    });
    callback?.success?.(response);
  } catch (error) {
    callback?.error?.(String(error));
  } finally {
    callback?.finally?.();
    LoadingStore.updateLocalLoadingAction(IS_BETTING, false);
  }
});
