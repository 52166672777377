import { observable } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getStore, ISicboBetItem } from '../store';

export const updateAddedBetItemsAction = mutatorAction(
  'updateAddedBetItemsAction',
  (schedulerId: string | undefined, addedBetItems: ISicboBetItem[] | undefined) => {
    if (!schedulerId) return;

    if (!addedBetItems) {
      getStore().addedBetItemsMap.delete(schedulerId);
      return;
    }

    getStore().addedBetItemsMap.set(schedulerId, observable.array(addedBetItems));
  }
);
