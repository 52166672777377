import { observable } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getStore } from '../store';
import { IGameSchema } from '../type';

export const updateGameSchemasAction = mutatorAction(
  'updateGameSchemasAction',
  (schedulerId: string | undefined, gameSchemas: IGameSchema[]) => {
    if (!schedulerId) return;
    getStore().gameSchemasMap.set(schedulerId, observable.array(gameSchemas));
  }
);
