import { orchestrator } from 'satcheljs';

import { LoadingStore } from '@totopkg/shared-util-common';

import { fetchGameInfoAction } from '../action';
import { getCurrentGameInfoAPI, getLatestGameInfoAPI } from '../api';
import { IS_FETCHING_GAME_INFO } from '../const';
import { updateCurrentGameInfoAction, updateHistoryResultsAction, updateLatestGameInfoAction } from '../mutator-action';
import { isFetchingGameInfoSelector } from '../selector';

const WAITING_FOR_NEW_MATCH_TIME_MS = 2000;

orchestrator(fetchGameInfoAction, actionMessage => {
  const { schedulerId, isOnMount, callback } = actionMessage;

  const isFetchingGameInfo = isFetchingGameInfoSelector();

  if (!schedulerId || isFetchingGameInfo) return callback?.finally?.();

  LoadingStore.updateLocalLoadingAction(IS_FETCHING_GAME_INFO, true);

  // const timeoutId: NodeJS.Timeout
  const timeoutId: any = setTimeout(
    async () => {
      try {
        const [currentGameInfo, latestGameInfo] = await Promise.all([
          getCurrentGameInfoAPI(schedulerId),
          getLatestGameInfoAPI(schedulerId)
        ]);

        updateCurrentGameInfoAction(schedulerId, currentGameInfo);
        updateLatestGameInfoAction(schedulerId, latestGameInfo);

        if (!isOnMount) {
          updateHistoryResultsAction(
            schedulerId,
            [
              {
                issueId: latestGameInfo.id,
                result: latestGameInfo.specialNumber
              }
            ],
            { isUnshift: true }
          );
        }

        clearTimeout(timeoutId);
        callback?.success?.();
      } catch (error) {
        callback?.error?.(String(error));
      } finally {
        LoadingStore.updateLocalLoadingAction(IS_FETCHING_GAME_INFO, false);
        callback?.finally?.();
      }
    },
    isOnMount ? 0 : WAITING_FOR_NEW_MATCH_TIME_MS
  );
});
