import { TSicboBetLocationType } from '../store';

export interface IGameSchema {
  id?: TSicboBetLocationType;
  gameId?: number;
  ratioWin?: number;
  minRatioWin?: number;
  maxRatioWin?: number;
  maxBetAmt?: number;
  minBetAmt?: number;

  // for format guide content, this could be unit or price
  betUnit?: number;
  winUnit?: number;
}
