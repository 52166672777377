import { observable } from 'mobx';
import { mutatorAction } from 'satcheljs';
import { addedBetItemsSelector } from '../selector';
import { getStore } from '../store';

export const removeAddedBetItemAction = mutatorAction('removeAddedBetItemAction', (schedulerId: string | undefined, betLocation: string | undefined) => {
  if (!schedulerId || !betLocation) return;

  const addedBetItems = addedBetItemsSelector(schedulerId)
  const newAddedBetItems = addedBetItems?.filter((betItem) => betItem.betLocation !== betLocation)

  if (!newAddedBetItems || newAddedBetItems.length === 0) {
    getStore().addedBetItemsMap.delete(schedulerId);
    return;
  }

  getStore().addedBetItemsMap.set(schedulerId, observable.array(newAddedBetItems));
})
