import { observable } from 'mobx';
import { orchestrator } from 'satcheljs';

import { transferSelectedToAddedBetItemsAction } from '../action';
import { clearSelectedBetItemsAction, updateAddedBetItemsAction } from '../mutator-action';
import { addedBetItemsSelector, selectedBetItemsSelector } from '../selector';

orchestrator(transferSelectedToAddedBetItemsAction, actionMessage => {
  const { schedulerId } = actionMessage;

  if (!schedulerId) return;

  const selectedBetItems = selectedBetItemsSelector(schedulerId);
  const newAddedBetItems = addedBetItemsSelector(schedulerId) ?? observable.array([]);

  selectedBetItems?.forEach(selectedBetItem => {
    const existBetItemIdx = newAddedBetItems.findIndex(addedBetItem => addedBetItem.betLocation === selectedBetItem.betLocation);

    if (existBetItemIdx !== -1) {
      newAddedBetItems[existBetItemIdx] = {
        betLocation: selectedBetItem.betLocation,
        betAmt: (newAddedBetItems[existBetItemIdx].betAmt || 0) + (selectedBetItem.betAmt || 0)
      };
    } else {
      newAddedBetItems.unshift(selectedBetItem);
    }
  });

  clearSelectedBetItemsAction(schedulerId);
  updateAddedBetItemsAction(schedulerId, newAddedBetItems);
});
