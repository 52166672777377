import { orchestrator } from 'satcheljs';

import { LoadingStore } from '@totopkg/shared-util-common';

import { cancelBetAction } from '../action';
import { postSicboCancelBetAPI } from '../api';
import { IS_CANCELLING_BET } from '../const';
import { isBettingSelector } from '../selector';

orchestrator(cancelBetAction, async actionMessage => {
  const { ticketId, callback } = actionMessage;

  const isBetting = isBettingSelector();

  if (!ticketId || isBetting) return callback?.finally?.();

  LoadingStore.updateLocalLoadingAction(IS_CANCELLING_BET, true);

  try {
    const response = await postSicboCancelBetAPI(ticketId);
    callback?.success?.(response);
  } catch (error) {
    callback?.error?.(String(error));
  } finally {
    callback?.finally?.();
    LoadingStore.updateLocalLoadingAction(IS_CANCELLING_BET, false);
  }
});
